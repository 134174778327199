import { useRef } from "react";
import { isIOS } from "../utils/isIOS";

export interface NativeGradingInterface {
  idle: () => void;
  fail: () => void;
  success: () => void;
}

type IOSGradingPayload = object | string;

interface IOSGradingInterface {
  idle: { postMessage: (payload: IOSGradingPayload) => void };
  fail: { postMessage: (payload: IOSGradingPayload) => void };
  success: { postMessage: (payload: IOSGradingPayload) => void };
}

// TEST:
// (function() { window.dispatchEvent(new CustomEvent('start', null)); })();
// (function() { window.dispatchEvent(new CustomEvent('pause', null)); })();
// (function() { window.dispatchEvent(new CustomEvent('update-coordinates', { detail: {alpha: 0, beta: 1, gamma: 0} })); })();

const useNativeGrading = (): NativeGradingInterface => {
  const isIOSDevice = useRef(isIOS());
  const androidGradingInterface: NativeGradingInterface = (window as any)
    .NativeGradingInterface;

  const iosGradingInterface: IOSGradingInterface = (window as any).webkit
    ?.messageHandlers;

  const nativeGrading: NativeGradingInterface = {
    idle: () => {
      if (isIOSDevice.current) {
        iosGradingInterface.idle.postMessage("");
      } else {
        androidGradingInterface.idle();
      }
    },

    fail: () => {
      if (isIOSDevice.current) {
        iosGradingInterface.fail.postMessage("");
      } else {
        androidGradingInterface.fail();
      }
    },

    success: () => {
      if (isIOSDevice.current) {
        iosGradingInterface.success.postMessage("");
      } else {
        androidGradingInterface.success();
      }
    },
  };

  return nativeGrading;
};

export default useNativeGrading;
