import { useRef } from "react";

interface TimerReturn {
  pause: () => void;
  start: () => void;
  reset: () => void;
}

type TimerFn = (
  finishCallback: () => void,
  delay: number,
  tickCallback?: (time: number) => void
) => TimerReturn;

const Timer: TimerFn = (callback, delay, tickCallback) => {
  let timerId: number | undefined,
    startTime: number,
    tickInterval: NodeJS.Timer,
    remaining = delay;

  const timerFinishedRef = useRef(false);

  const getElapsedTime = () => Date.now() - startTime;

  const pause = () => {
    if (timerFinishedRef.current) return;

    window.clearTimeout(timerId);
    clearInterval(tickInterval);
    timerId = undefined;

    remaining -= getElapsedTime();
  };

  const start = () => {
    if (timerId) return;

    startTime = Date.now();
    timerId = window.setTimeout(() => {
      callback();
      timerFinishedRef.current = true;
    }, remaining);

    if (tickCallback)
      tickInterval = setInterval(() => {
        tickCallback(remaining - getElapsedTime());
      }, 1000);
  };

  const reset = () => {
    window.clearTimeout(timerId);
    clearInterval(tickInterval);
    timerId = undefined;

    remaining = delay;
    timerFinishedRef.current = false;
  };

  return { pause, start, reset };
};

export default Timer;
