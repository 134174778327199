import { useRef, useEffect } from 'react';
import './App.css';
import {
  Vector3,
  Mesh,
  CircleGeometry,
  MeshLambertMaterial,
  TextureLoader,
  Vector2,
  Scene,
  PerspectiveCamera,
  SpotLight,
  Color,
  WebGLRenderer,
  PCFSoftShadowMap,
  SphereGeometry,
  BoxGeometry,
} from "three";
import Timer from './utils/timer';
import throttle from "lodash/throttle";
import useNativeGrading from './hooks/useNativeGrading';


const EventNames = {
  START: "start",
  PAUSE: "pause",
  UPDATE_COODINATES: "update-coordinates"
}

const NO_MOVEMENT_TIME = 5000;
const RETRY_WARNING_MODAL_LIMIT = 3;

function App() {
  const isInit = useRef(true);
  const nativeGrading = useNativeGrading();

  const power = useRef<number>(0.2);
  const velocity = useRef({ x: 0, y: 0, z: 0 });
  const checkPointPositions = [
    new Vector3(0, 15, 0),
    new Vector3(13, -18, 0),
    new Vector3(-13, -18, 0),
  ];
  const totalWarningModalClosedRef = useRef(0);
  const canControlVelocityRef = useRef(true);
  const isFreezedGame = useRef(true); 
  const gameAnimationFrame = useRef<number>();
  const followedCheckpoint =
    useRef<Mesh<CircleGeometry, MeshLambertMaterial>>();
  const checkpointRadius = 4;
  const checkBallIsNotMoving = () =>
    Math.abs(velocity.current.x) < 0.02 && Math.abs(velocity.current.y) < 0.02;

  const onTestFailed = () => {
    isFreezedGame.current = true;
    nativeGrading.fail();
  };

  const onNoMovementExceeded = () => {
    if (totalWarningModalClosedRef.current < RETRY_WARNING_MODAL_LIMIT) {
      warningModalTimerRef.current.reset()
      isFreezedGame.current = true;
      totalWarningModalClosedRef.current += 1;
      nativeGrading.idle();
    } else {
      onTestFailed();
    }
  };

  const warningModalTimerRef = useRef(
    Timer(onNoMovementExceeded, NO_MOVEMENT_TIME)
  );

  const getCheckpoints = () => {
    const circles = [];
    const texture = new TextureLoader().load("assets/images/motion-target.svg");

    for (let i = 0; i < 3; i++) {
      const geometry = new CircleGeometry(checkpointRadius, 32);
      texture.center = new Vector2(0.5, 0.5);

      const material = new MeshLambertMaterial({
        map: texture,
        transparent: true,
        opacity: 1,
      });

      const circle = new Mesh(geometry, material);
      circle.position.x = checkPointPositions[i].x;
      circle.position.y = checkPointPositions[i].y;
      circle.position.z = checkPointPositions[i].z;
      circle.receiveShadow = true;
      circles.push(circle);
    }
    return circles;
  };

  const startTargetAnimation = () => {
    // Define the animation parameters
    const duration = 500; // duration of the animation in milliseconds

    const initialSizeScale = 1;
    const targetSizeScale = 0.5; // target scale factor

    // Create a loop that updates the scale of the cube over time
    let startTime = 0;

    const animateScale = (currentTime: number) => {
      if (!followedCheckpoint.current) return;

      if (!startTime) startTime = currentTime;

      const elapsed = currentTime - startTime;
      const progress = elapsed / duration;

      requestAnimationFrame(animateScale);

      // Calculate the scale factor based on the elapsed time
      const sizeScale =
        initialSizeScale +
        (targetSizeScale - initialSizeScale) * Math.min(progress, 1);

      followedCheckpoint.current.scale.set(sizeScale, sizeScale, 1);
    };
    animateScale(0);
  };

  const startGameRender = () => {
    // Uncomment below to test on desktop

    // window.onkeydown = (e) => {
    //   if (["KeyD", "ArrowRight"].some((code) => e.code === code)) 
    //     velocity.current.x += 1 * power.current * 2;

    //   if (["KeyA", "ArrowLeft"].some((code) => e.code === code))
    //     velocity.current.x += -1 * power.current * 2;

    //   if (["KeyW", "ArrowUp"].some((code) => e.code === code))
    //     velocity.current.y += 1 * power.current;

    //   if (["KeyS", "ArrowDown"].some((code) => e.code === code))
    //     velocity.current.y += -1 * power.current;
    // };

    const friction = 0.99;
    const bounciness = 0.5;

    const ballRadius = 3;
    const ballCircumference = Math.PI * ballRadius * 2;
    const ballVelocity = new Vector3();
    const ballRotationAxis = new Vector3(0, 1, 0);

    const gWidth = window.innerWidth;
    const gHeight = window.innerHeight;
    const ratio = gWidth / gHeight;
    const borders = [18, 32]; //indicate where the ball needs to move in mirror position

    //set the scene
    const scene = new Scene();
    scene.background = new Color(0xeaeaea);

    //set the camera
    const camera = new PerspectiveCamera(35, ratio, 0.1, 1000);
    camera.position.z = 120;

    //  set the renderer
    const renderer = new WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);

    //properties for casting shadow
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;

    renderer.setSize(gWidth, gHeight);

    const container = document.getElementById("render-container");

    if (container) container.appendChild(renderer.domElement);

    /*
     *
     * ADD MESH TO SCENE
     *
     */

    // create and add the ball
    const geometryBall = new SphereGeometry(ballRadius, 24, 24);

    const ballTex = new TextureLoader().load("assets/images/ball-texture.svg");

    const materialBall = new MeshLambertMaterial({
      map: ballTex,
    });
    const ball = new Mesh(geometryBall, materialBall);

    ball.castShadow = true;
    ball.receiveShadow = false;
    ball.position.z = 1;
    scene.add(ball);

    //set the lights
    const light = new SpotLight(0xffffff, 0.7);
    light.position.set(5, -5, 90);
    scene.add(light);

    const light2 = new SpotLight(0xffffff, 0.6);
    light2.castShadow = true;
    light2.shadow.mapSize.width = 2048;
    light2.shadow.mapSize.height = 2048;
    light2.position.set(-4, -4, 60);
    scene.add(light2);

    const light3 = new SpotLight(0xffffff, 0.3);
    light3.position.set(-50, 0, 1);
    light3.target = ball;
    scene.add(light3);

    const light4 = new SpotLight(0xffffff, 0.3);
    light4.position.set(50, 0, 1);
    light4.target = ball;
    scene.add(light4);

    const light5 = new SpotLight(0xffffff, 0.3);
    light5.position.set(0, 50, 1);
    light5.target = ball;
    scene.add(light5);

    const light6 = new SpotLight(0xffffff, 0.3);
    light6.position.set(0, -50, 1);
    light6.target = ball;
    scene.add(light6);

    // create and add the field
    const fieldRatio = ratio;

    const width = 150;
    const height = width / fieldRatio;

    const material = new MeshLambertMaterial({
      color: "#ffffff",
    });
    const geometry = new BoxGeometry(width, height, 1);
    const field = new Mesh(geometry, material);

    field.receiveShadow = true;
    field.position.z = -1;
    scene.add(field);

    const checkpoints = getCheckpoints();
    for (const checkpoint of checkpoints) scene.add(checkpoint);

    /*
     *
     * ANIMATION STEP
     *
     */

    const render = () => {
      if (isFreezedGame.current) {
        gameAnimationFrame.current = requestAnimationFrame(render);
        return;
      }

      // checkpoint collision check
      for (const checkpoint of checkpoints)
        if (
          ball.position.distanceTo(checkpoint.position) <
            checkpointRadius + ballRadius / 2 &&
          !checkpoint.userData["visited"]
        ) {
          canControlVelocityRef.current = false;
          checkpoint.userData = { visited: true };

          checkpoint.material.opacity = 0.8;

          followedCheckpoint.current = checkpoint;
          startTargetAnimation();
        }

      if (followedCheckpoint.current) {
        const distanceDifferences = followedCheckpoint.current.position.clone();
        distanceDifferences.sub(ball.position);

        velocity.current.x = distanceDifferences.x / 20;
        velocity.current.y = distanceDifferences.y / 20;

        if (checkBallIsNotMoving()) {
          scene.remove(followedCheckpoint.current);
          followedCheckpoint.current = undefined;
          canControlVelocityRef.current = true;

          if (checkpoints.every((elem) => elem.userData["visited"])) {
            isFreezedGame.current = true;
            nativeGrading.success();
          }
        }
      }

      // add velocity to ball
      ball.position.x += velocity.current.x;
      ball.position.y += velocity.current.y;

      if (checkBallIsNotMoving()) {
        velocity.current.x = 0;
        velocity.current.y = 0;
      }

      // Figure out the rotation based on the velocity and radius of the ball...
      ballVelocity.set(
        velocity.current.x,
        velocity.current.y,
        velocity.current.z
      );
      ballRotationAxis.set(0, 0, 1).cross(ballVelocity).normalize();
      const velocityMag = ballVelocity.length();
      const rotationAmount = (velocityMag * (Math.PI * 2)) / ballCircumference;
      ball.rotateOnWorldAxis(ballRotationAxis, rotationAmount);

      //reducing speed by friction
      velocity.current.x *= friction;
      velocity.current.y *= friction;

      //validate ball is withing its borders otherwise go in the mirror direction
      if (Math.abs(ball.position.x) > borders[0]) {
        velocity.current.x *= -bounciness;
        ball.position.x = ball.position.x < 0 ? borders[0] * -1 : borders[0];
      }

      if (Math.abs(ball.position.y) > borders[1]) {
        velocity.current.y *= -bounciness;
        ball.position.y = ball.position.y < 0 ? borders[1] * -1 : borders[1];
      }

      //render the page
      renderer.render(scene, camera);

      gameAnimationFrame.current = requestAnimationFrame(render);
    };

    render();
  };

  const listenNativeEvents = (bind = true) => { 
    const onStart = (_: CustomEvent) => {
      isFreezedGame.current = false
      warningModalTimerRef.current.reset()
      warningModalTimerRef.current.start()
    }

    const onPause = (_: CustomEvent) => {
      isFreezedGame.current = true
      warningModalTimerRef.current.pause()
    }
    
    if(bind) {
      window.addEventListener(EventNames.START, onStart as EventListener)
      window.addEventListener(EventNames.PAUSE, onPause as EventListener)
    } else {
      window.removeEventListener(EventNames.START, onStart as EventListener)
      window.removeEventListener(EventNames.PAUSE, onPause as EventListener)
    }
  }

  const bindVelocityToSensor = (bind = true) => {
    const updateRate = 1 / 5; // Sensor refresh rate

    const startThrottledTimer = throttle(
      () => {
        if(!isFreezedGame.current) {
          warningModalTimerRef.current.start() 
        }
      },
      500
    );

    const resetThrottledTimer = throttle(
      () => warningModalTimerRef.current.reset(),
      500
    );

    const onUpdateCoordinates = (event:  CustomEvent<{alpha: number, beta: number, gamma: number}>) => {
      if (!canControlVelocityRef.current || isFreezedGame.current) return;

      // Expose each orientation angle in a more readable way

      const { beta, gamma } = event.detail 

      // alpha: rotation_degrees
      // beta: frontToBack_degrees
      // gamma: leftToRight_degrees

      // Update velocity according to how tilted the phone is
      velocity.current.x += (gamma ? gamma : 0) * updateRate * power.current;
      velocity.current.y += (beta ? beta : 0) * updateRate * power.current;

      if (checkBallIsNotMoving())  startThrottledTimer(); 
      else resetThrottledTimer();
    }


    if(bind) {
      window.addEventListener(EventNames.UPDATE_COODINATES, onUpdateCoordinates as EventListener)

    } else {
      window.removeEventListener(EventNames.UPDATE_COODINATES, onUpdateCoordinates as EventListener)

    }
  };

  useEffect(() => {
    if (isInit.current) {
      listenNativeEvents();
      bindVelocityToSensor();
      startGameRender();
    }
    return () => {
      //TODO: verify this on staging
      if(!isInit.current && gameAnimationFrame.current) {
        window.cancelAnimationFrame(gameAnimationFrame.current);
        listenNativeEvents(false);
        bindVelocityToSensor(false);
      } 

      isInit.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="render-container"></div>
  );
}


export default App;
